<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Daftar Data Surat Kuasa</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-50"
                    id="filter-input"
                    v-model="filterSk"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <div class="d-inline-block float-right">
                    <router-link
                      class="btn btn-primary dark-btn-primary"
                      :to="{ name: 'kerjasama.sk.tambah' }"
                      role="button"
                      >Tambah Data<i class="ri-add-line ml-2"></i
                    ></router-link>
                  </div>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="thead"
                :items="listData"
                class="mt-2"
                :busy="isLoadingSk"
                :filter="filterSk"
                :per-page="perPageSk"
                :current-page="currentPageSk"
                @filtered="onFilteredSk"
                show-empty
              >
                <template v-slot:cell(nomorSuratKuasa)="listData">
                  <div>
                    {{
                      listData.item.nomorSuratKuasa
                        ? listData.item.nomorSuratKuasa
                        : '-'
                    }}
                  </div>
                </template>
                <template v-slot:cell(aksi)="listData">
                  <div class="d-flex justify-content-center">
                    <router-link
                      class="btn iq-bg-success rounded-sm mr-2 mb-1"
                      size="sm"
                      role="button"
                      :to="{
                        name: 'kerjasama.sk.edit',
                        params: { idSk: listData.item.idSk }
                      }"
                      ><i :class="'ri-ball-pen-fill m-0'"></i
                    ></router-link>
                    <router-link
                      class="btn iq-bg-info rounded-sm ml-2 mb-1"
                      v-b-tooltip="'Lihat Detail'"
                      size="sm"
                      role="button"
                      :to="{
                        name: 'kerjasama.sk.detail',
                        params: { idSk: listData.item.idSk }
                      }"
                      ><i class="ri-search-line m-0"></i
                    ></router-link>
                    <b-button
                      class="btn iq-bg-secondary rounded-sm ml-2 mb-1"
                      variant="light"
                      @click="
                        showDokumenKs(
                          listData.item.idSk
                          // listData.item.nomorSuratKuasa
                        )
                      "
                      ><i class="ri-file mr-1"></i>Lihat Dokumen</b-button
                    >
                    <!-- <a
                      v-if="listData.item.statusTTE == 1"
                      :href="listData.item.linkTTE"
                      target="_blank"
                    > -->
                    <b-button
                      v-if="listData.item.statusTTE == 1"
                      class="btn bg-primary rounded-sm ml-2 mb-1"
                      variant="outline"
                      @click="downloadDokumenTte(listData.item.idSk)"
                      ><i class="ri-file mr-1"></i>Dokumen TTE</b-button
                    >
                    <!-- </a> -->
                    <b-button
                      v-else
                      class="btn iq-bg-primary rounded-sm ml-2 mb-1 cursor-not-allowed"
                      variant="outline"
                      v-b-tooltip="'Tidak ada dokumen TTE'"
                      ><i class="ri-file mr-1"></i>Dokumen TTE</b-button
                    >
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div class="text-danger font-weight-bold mx-auto text-center">
                    Data tidak ditemukan
                  </div>
                </template>
                <template #emptyfiltered>
                  <div class="text-danger font-weight-bold mx-auto text-center">
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPageSk"
                      :options="pageOptionsSk"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPageSk"
                    :total-rows="totalRowsSk"
                    :per-page="perPageSk"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import { mapState } from 'vuex'
import { ID_GROUP } from '@/types'
/** Date Format */
import moment from 'moment'

export default {
  name: 'kerjasama.sk',
  props: {
    /**
     * data yang akan tampil.
     */
    // listData: {
    //   type: [String, Array],
    //   default: ''
    // }
  },
  computed: {
    ...mapState('authService', ['token'])
  },
  mounted() {
    /** Innit data */
    this.innitData()
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFilteredSk(filteredItems) {
      this.totalRowsSk = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Get data Kerjasama SK
     */
    async getListTransaksiSk_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListSuratKuasa`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listData = res.data
          /** Set the initial number of items */
          this.totalRowsSk = this.listData.length
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    async showDokumenKs(idSk, nomorSuratKuasa) {
      console.log(nomorSuratKuasa)
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getCetakanSK?idSK=${idSk}`,
        method: 'POST',
        responseType: 'blob',
        // data: { idSk: idSk },
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          /** Cara 1 */
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url, '_blank')
          setTimeout(() => window.URL.revokeObjectURL(url), 10000)
          /** Cara 2 */
          // var url = window.URL.createObjectURL(res.data)
          // var a = document.createElement('a')
          // a.href = url
          // a.download = `Dokumen_SK_${idSk}_${nomorSuratKuasa}.pdf`
          // a.click()
          // a.remove()
          // setTimeout(() => window.URL.revokeObjectURL(url), 100)

          // window.open(url, '_blank')
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('File tidak ditemukan')
          throw new Error(err)
        })
      return result
    },
    async downloadDokumenTte(idSk) {
      console.log('id: ', idSk)
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDokumenSkTTE?idSK=${idSk}`,
        method: 'POST',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          /** Cara 1 */
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url, '_blank')
          setTimeout(() => window.URL.revokeObjectURL(url), 10000)
          /** Cara 2 */
          // var url = window.URL.createObjectURL(res.data)
          // var a = document.createElement('a')
          // a.href = url
          // a.download = `Dokumen_SK_${idSk}_${nomorSuratKuasa}.pdf`
          // a.click()
          // a.remove()
          // setTimeout(() => window.URL.revokeObjectURL(url), 100)

          // window.open(url, '_blank')
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('File tidak ditemukan')
          throw new Error(err)
        })
      return result
    },
    /**
     * Init Data
     */
    async innitData() {
      /** Get List Data */
      this.isLoadingSk = true
      await Promise.all([this.getListTransaksiSk_()])
      this.isLoadingSk = false
    }
  },
  data() {
    return {
      listIdGroup: ID_GROUP,
      idGroup: store.state.authService.idGroup,
      // Tabel SK Options
      isLoadingSk: false,
      filterSk: null,
      perPageSk: 5,
      pageOptionsSk: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPageSk: 1,
      totalRowsSk: 1,
      // Date Picker
      basicConfig: {
        // dateFormat: 'd-m-Y'
      },
      basicMinTimeConfig: {
        minDate: moment(new Date()).format('YYYY-MM-DD')
      },
      // Table
      thead: [
        {
          label: 'Nomor Surat Kuasa',
          key: 'nomorSuratKuasa',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Tanggal Surat',
          key: 'tanggalSurat',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nama Kegiatan',
          key: 'namaKegiatan',
          class: 'text-center',
          sortable: true
        },
        // { label: 'Kontak Mitra', key: 'kontakMitra', class: 'text-center' },
        { label: 'Aksi', key: 'aksi', class: 'text-center' }
      ],
      listData: []
    }
  }
}
</script>
